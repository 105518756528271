import React, { lazy } from "react";
//Need this to switch pages
//V6 React Router Dom Updates
import { Routes, Route } from "react-router-dom";

//Pages
import ContactPage from "../pages/Contact.js";
const HomePage = lazy(() => import("../pages/Home.js"));
const AvailablePuppiesPage = lazy(() => import("../pages/AvailablePuppies.js"));
const StudServicePage = lazy(() => import("../pages/StudService.js"));
const AboutUsPage = lazy(() => import("../pages/AboutUs.js"));
const PastPuppiesPage = lazy(() => import("../pages/PastPuppies.js"));
const OurCustomersPage = lazy(() => import("../pages/OurCustomers.js"));
const BlogPage = lazy(() => import("../pages/BlogHome.js"));
const ResourceDocumentsPage = lazy(() =>
  import("../pages/ResourceDocuments.js")
);
const SalesAgreementFormPage = lazy(() =>
  import("../pages/SalesAgreementForm.jsx")
);
// Blog Pages
const WhatToKnowWhenBuyingACorgiPuppy = lazy(() =>
  import("../pages/blog_pages/what_to_know_when_buying_a_corgi_puppy.js")
);
const EightBestPlacesSF = lazy(() =>
  import(
    "../pages/blog_pages/8_best_places_to_take_your_corgi_to_in_san_francisco.js"
  )
);
// const PatriotPawsServiceDogs = lazy(() =>
//   import("../pages/blog_pages/patriot_paws_service_dogs.js")
// );
//404 Page
const NotFoundPage = lazy(() => import("../pages/NotFoundPage.js"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy.js"));

const RoutePaths = ({ history }) => (
  <Routes history={history}>
    {/* A <Routes> looks through its children <Route> and renders the first one that matches the current URL. */}
    <Route path="/" element={<HomePage />} />
    <Route path="/available-puppies" element={<AvailablePuppiesPage />} />
    <Route path="/stud-service" element={<StudServicePage />} />
    <Route path="/about-us" element={<AboutUsPage />} />
    <Route path="/past-puppies" element={<PastPuppiesPage />} />
    <Route path="/our-customers" element={<OurCustomersPage />} />
    <Route path="/contact" element={<ContactPage />} />
    <Route path="/blog" element={<BlogPage />} />
    <Route path="/resource-documents" element={<ResourceDocumentsPage />} />
    <Route path="/sales-agreement-form" element={<SalesAgreementFormPage />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />

    {/* Blog Paths */}
    <Route
      path="/blog/what_to_know_when_buying_a_corgi_puppy"
      element={<WhatToKnowWhenBuyingACorgiPuppy />}
    />
    <Route
      path="/blog/8_best_places_to_take_your_corgi_to_in_san_francisco"
      element={<EightBestPlacesSF />}
    />
    {/* <Route
      path="/blog/charities/patriot_paws_service_dogs"
      element={<PatriotPawsServiceDogs />}
    /> */}

    {/* 404 page */}
    <Route path="/404" element={<NotFoundPage />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);
export default RoutePaths;
