/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getForm = /* GraphQL */ `
  query GetForm($id: ID!) {
    getForm(id: $id) {
      id
      token
      firstname
      lastname
      phone
      email
      recommender
      foundus
      subject
      message
      dogexperience
      householdpets
      peopleyouknow
      familymember
      financiallyprepared
      patience
      familynetwork
      physicalactivities
      mentalpreparedness
      caretaker
      allergic
      allergictest
      dogsallowed
      fencedyard
      housetraining
      trainingclasses
      breeding
      familypet
      specialtraining
      customerready
      puppystress
      concernscommunication
      petinsurance
      medicalemergency
      shedding
      buyerprotections
      norefundpolicy
      petsmeetnewpuppy
      ownership
      returnpolicy
      fourfigures
      nodeposits
      lineofwork
      worklifestyle
      primarycaretaker
      placeofresidence
      puppyhome
      alonetime
      familychildren
      teentroubles
      puppygender
      activitylevel
      championshipshows
      oldpets
      puppysearch
      puppyreturn
      puppygiveaway
      vetinfo
      recap
      waitlist
      meeting
      privacy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listForms = /* GraphQL */ `
  query ListForms(
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        token
        firstname
        lastname
        phone
        email
        recommender
        foundus
        subject
        message
        dogexperience
        householdpets
        peopleyouknow
        familymember
        financiallyprepared
        patience
        familynetwork
        physicalactivities
        mentalpreparedness
        caretaker
        allergic
        allergictest
        dogsallowed
        fencedyard
        housetraining
        trainingclasses
        breeding
        familypet
        specialtraining
        customerready
        puppystress
        concernscommunication
        petinsurance
        medicalemergency
        shedding
        buyerprotections
        norefundpolicy
        petsmeetnewpuppy
        ownership
        returnpolicy
        fourfigures
        nodeposits
        lineofwork
        worklifestyle
        primarycaretaker
        placeofresidence
        puppyhome
        alonetime
        familychildren
        teentroubles
        puppygender
        activitylevel
        championshipshows
        oldpets
        puppysearch
        puppyreturn
        puppygiveaway
        vetinfo
        recap
        waitlist
        meeting
        privacy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
