import React, { Component } from "react";
import "../index.css";
import { Helmet } from "react-helmet";
import CharitySponsorshipRow from "../shared_components/CharitySponsorshipRow.js";
// import PurinaProPlanInfo from "../shared_components/PurinaProPlanInfo";
import ScreeningQuestionForm from "./ScreeningQuestionForm";
//Need these for font awesome icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faSmileBeam,
  faExclamationTriangle,
  faPhoneSquare,
  faSms,
} from "@fortawesome/free-solid-svg-icons";
// import { faYoutube, faTwitter, faInstagram, faFacebookSquare} from '@fortawesome/free-brands-svg-icons'
library.add(
  faCalendarCheck,
  faSmileBeam,
  faExclamationTriangle,
  faPhoneSquare,
  faSms
); //import font awesome icons

class Contact extends Component {
  render() {
    return (
      <main className="main">
        <Helmet>
          <title>Contact - Bay Area Corgis</title>
          <meta
            name="description"
            content="Interested in our red and white or our tri-color Corgi puppies? Contact Bay Area Corgis for our available Corgis for sale in San Francisco!"
          />
          <link rel="canonical" href="/contact" />
        </Helmet>
        <section className="contact section bd-grid fade-in" id="contact">
          <h1 className="section-title">Contact</h1>
          <article className="primary-contact__container">
            <h2>Reach us by text!</h2>
            <div className="contact-button-container">
              <a
                className="button"
                href="sms:415-996-5669"
                target="_blank"
                rel="noreferrer noopener"
              >
                <FontAwesomeIcon icon={faSms} /> Text
              </a>
              <a
                className="button"
                href="tel:415-996-5669"
                target="_blank"
                rel="noreferrer noopener"
              >
                <FontAwesomeIcon icon={faPhoneSquare} /> Call
              </a>
            </div>

            <article className="contact-blurb bd-grid">
              <h2>
                <FontAwesomeIcon icon={faCalendarCheck} />
                &ensp;Schedule an in-person meeting to see our Corgis!{" "}
              </h2>
              <p className="contact-text">
                {" "}
                Text Message or our Contact Form (down below) are the best ways
                to reach us 24/7.{" "}
              </p>
            </article>
          </article>

          <div className="contact__container bd-grid">
            <div className="contact__img">
              <img
                src={require("../images/contact.jpg")}
                alt="cute redhead corgi on pink sheets"
              />
            </div>

            <ScreeningQuestionForm />

            <div className="contact__img fade-in-left">
              <img
                src={require("../images/ending.png")}
                alt="cute redhead corgi smiling"
              />
              <p className="contact-end-comment">
                Thanks for Visiting Bay Area Corgis!
              </p>
            </div>
          </div>
        </section>
        {/* <PurinaProPlanInfo /> */}
        <CharitySponsorshipRow />
      </main>
    );
  }
}

export default Contact;
