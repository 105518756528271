import React, { Component } from "react";
import "../index.css";

class CharityRow extends Component {
  render() {
    return (
      <section className="charity-sponsorship-container fade-in-left">
        <section className="logo-row">
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.akcchf.org/donate2015/chf-donate.html"
          >
            <img
              src={require("../images/collab-logos/charities/AKC_CanineHealthFoundation_Membership-logo.webp")}
              alt="AKC Canine Health Foundation Logo"
              id="AKC-canine-health-foundation-logo"
            />
          </a>

          {/* <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.avmf.org/forms/donate-to-avmf/"
          >
            <img
              src={require("../images/collab-logos/charities/AVMF_Charity.png")}
              alt="AVMF Logo"
              id="AVMF-logo"
            />
          </a> */}

          {/* <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://patriotpaws.org/donate/"
          >
            <img
              src={require("../images/collab-logos/charities/PPSD_Logo_Standard.webp")}
              alt="Patriot PAWS Service Dogs Logo"
              id="PPSD-logo"
            />
          </a> */}
        </section>

        <article className="about-charities">
          <h3>Charities We Support</h3>
          <p>
            We have made charitable donations to these non-profit organizations
            that have made a difference in the lives of both people and animals.
          </p>
          <p>
            We work to spread awareness and support for these organizations,
            which you can learn in more detail in our blog series: Corgi By The
            Bay.{" "}
          </p>
          <p>
            Also, to clarify our dog breeding program has no direct affliation
            or formal partnership with any of these organizations .{" "}
          </p>
          <p>
            We simply raise family pets, which do not pertain to the scope of
            the organizations above like Canine Health Research or Service
            Canine Training, for example.{" "}
          </p>
        </article>
      </section>
    );
  }
}

export default CharityRow;
