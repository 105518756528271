import React, { Component } from "react";
import "../index.css";
//Need this to switch pages
//V6 React Router Dom Updates
import { Link } from "react-router-dom";
//Need these for font awesome icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneSquare } from "@fortawesome/free-solid-svg-icons";
library.add(faPhoneSquare); //import font awesome icons

class Footer extends Component {
  render() {
    return (
      <footer className="footer fade-in">
        <section className="footer-header-container">
          {/* Backslash directs to home */}
          <Link to="/">
            <p className="footer__title">Bay Area Corgis</p>
          </Link>
          <a
            id="phone-footer-contact"
            className="footer__link"
            href="sms:415-996-5669"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FontAwesomeIcon icon={faPhoneSquare} /> 415-996-5669
          </a>
        </section>

        <section className="footer__menu">
          <ul className="footer__list">
            <li className="footer__item">
              <Link to="/" className="footer__link">
                Home
              </Link>
            </li>
            <li className="footer__item">
              <Link to="/available-puppies" className="footer__link">
                Available Puppies
              </Link>
            </li>
            <li className="footer__item">
              <Link to="/stud-service" className="footer__link">
                Stud Service
              </Link>
            </li>
            <li className="footer__item">
              <Link to="/about-us" className="footer__link">
                About Us
              </Link>
            </li>
            <li className="footer__item">
              <Link to="/past-puppies" className="footer__link">
                Past Puppies
              </Link>
            </li>
            <li className="footer__item">
              <Link to="/our-customers" className="footer__link">
                Our Customers
              </Link>
            </li>
            <li className="footer__item">
              <Link to="/contact" className="footer__link">
                Contact
              </Link>
            </li>
            <li className="footer__item">
              <Link to="/blog" className="footer__link">
                Blog
              </Link>
            </li>
          </ul>
        </section>

        <section className="footer-blurb">
          <p>&#169; 2025 Bay Area Corgis copyright all right reserved</p>
          <Link to="/privacy-policy" className="privacy-policy-link">
            Privacy Policy
          </Link>
        </section>
      </footer>
    );
  }
}

export default Footer;
