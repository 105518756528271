/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createForm = /* GraphQL */ `
  mutation CreateForm(
    $input: CreateFormInput!
    $condition: ModelFormConditionInput
  ) {
    createForm(input: $input, condition: $condition) {
      id
      token
      firstname
      lastname
      phone
      email
      recommender
      foundus
      subject
      message
      dogexperience
      householdpets
      peopleyouknow
      familymember
      financiallyprepared
      patience
      familynetwork
      physicalactivities
      mentalpreparedness
      caretaker
      allergic
      allergictest
      dogsallowed
      fencedyard
      housetraining
      trainingclasses
      breeding
      familypet
      specialtraining
      customerready
      puppystress
      concernscommunication
      petinsurance
      medicalemergency
      shedding
      buyerprotections
      norefundpolicy
      petsmeetnewpuppy
      ownership
      returnpolicy
      fourfigures
      nodeposits
      lineofwork
      worklifestyle
      primarycaretaker
      placeofresidence
      puppyhome
      alonetime
      familychildren
      teentroubles
      puppygender
      activitylevel
      championshipshows
      oldpets
      puppysearch
      puppyreturn
      puppygiveaway
      vetinfo
      recap
      waitlist
      meeting
      privacy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateForm = /* GraphQL */ `
  mutation UpdateForm(
    $input: UpdateFormInput!
    $condition: ModelFormConditionInput
  ) {
    updateForm(input: $input, condition: $condition) {
      id
      token
      firstname
      lastname
      phone
      email
      recommender
      foundus
      subject
      message
      dogexperience
      householdpets
      peopleyouknow
      familymember
      financiallyprepared
      patience
      familynetwork
      physicalactivities
      mentalpreparedness
      caretaker
      allergic
      allergictest
      dogsallowed
      fencedyard
      housetraining
      trainingclasses
      breeding
      familypet
      specialtraining
      customerready
      puppystress
      concernscommunication
      petinsurance
      medicalemergency
      shedding
      buyerprotections
      norefundpolicy
      petsmeetnewpuppy
      ownership
      returnpolicy
      fourfigures
      nodeposits
      lineofwork
      worklifestyle
      primarycaretaker
      placeofresidence
      puppyhome
      alonetime
      familychildren
      teentroubles
      puppygender
      activitylevel
      championshipshows
      oldpets
      puppysearch
      puppyreturn
      puppygiveaway
      vetinfo
      recap
      waitlist
      meeting
      privacy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteForm = /* GraphQL */ `
  mutation DeleteForm(
    $input: DeleteFormInput!
    $condition: ModelFormConditionInput
  ) {
    deleteForm(input: $input, condition: $condition) {
      id
      token
      firstname
      lastname
      phone
      email
      recommender
      foundus
      subject
      message
      dogexperience
      householdpets
      peopleyouknow
      familymember
      financiallyprepared
      patience
      familynetwork
      physicalactivities
      mentalpreparedness
      caretaker
      allergic
      allergictest
      dogsallowed
      fencedyard
      housetraining
      trainingclasses
      breeding
      familypet
      specialtraining
      customerready
      puppystress
      concernscommunication
      petinsurance
      medicalemergency
      shedding
      buyerprotections
      norefundpolicy
      petsmeetnewpuppy
      ownership
      returnpolicy
      fourfigures
      nodeposits
      lineofwork
      worklifestyle
      primarycaretaker
      placeofresidence
      puppyhome
      alonetime
      familychildren
      teentroubles
      puppygender
      activitylevel
      championshipshows
      oldpets
      puppysearch
      puppyreturn
      puppygiveaway
      vetinfo
      recap
      waitlist
      meeting
      privacy
      createdAt
      updatedAt
      __typename
    }
  }
`;
