import React, { Component } from "react";
import "./index.css";
import ScrollToTop from "./shared_components/ScrollToTop.js";
//Need this to switch pages
//V6 React Router Dom Updates
import { BrowserRouter } from "react-router-dom";
import NavBar from "./shared_components/NavBar.js";
import RoutePaths from "./shared_components/RoutePaths.js";
import Footer from "./shared_components/Footer.js";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <section className="app-container">
          <ScrollToTop />
          {/* wrap the code in BrowserRouter. 
            BrowserRouter is used to direct to new pages when buttons are clicked */}
          <NavBar />
          <RoutePaths />
          <Footer />
        </section>
      </BrowserRouter>
    );
  }
}
export default App;
